
export const getHotelSort = (sort: string) => {
  if (sort === 'recommend') {
    return 0;
  }
  if (sort === 'asc') {
    return 1;
  }
  if (sort === 'desc') {
    return 2;
  }

  return 0;
}