import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom"

//パーツをインポート
import {PartsStep, PartsTotalPrice,} from "./parts"
import {/*SectionFlight,*/ SectionPlan, SectionStay,} from "./section/PlanSelectTop"
import {SearchNotFound} from "./"

import TopSearchbox from "./search/TopSearchbox";

// MainTemplate
import MainTemplate from "./MainTemplate";

// scss
import "../scss/common.scss";
import "../scss/component.scss";

// for api
import {apiClient} from "../utils/apiClient";

// // query parser
// import qs from 'qs';

// types
import {IActivity} from "../types/Activity";
import {PlanSearch, /*searchResult*/} from "../types/Search";
// import { AxiosResponse } from "axios";

// // フライト
// import {
//   // Flights,
//   // FlightType,
//   FlightEntity,
//   // reservationFlight,
//   // viaReservationFlight,
//   // ReservationFlight,
//   // ViaReservationFlight,
//   // AirLines,
//   // FReservationFlightEntity,
//   // TravelType,
//   // Airline
// } from "../types/Flight";

// ホテル
import {Hotel, HotelAllSearchResponse, hotelSearchQuery, selectHotelDetail} from "../types/Hotel";
import {
  PlanSelectFormData,
  PlanSelectFromHotelType, /*PlanSelectFromHotelType, PlanSelectFromFlightType*/
} from "../types/planSelectForm";
import {FreeSpace} from "../types/Freespace";


// lib
import {dateFormat, /*rangeDate*/} from "../utils/convert"
// import { ContactSupportOutlined } from "@material-ui/icons";
// import {Seller} from "../types/seller";
// import {
//   getFlightCodeLabel,
//   getFlightCodeByLabel,
//   getFlightTotalPrice,
//   getFlightByTransit,
//   getCheapestRoute, isNotCurrentFlights,
// } from "../utils/flight";
import {getHotel, getHotelAreaCode, getHotelRoomPeopleLabel} from "../utils/hotel";
// import {defaultSearchParams} from "../defaults/search";
import {sellerInfo} from "../types/sller";
import {defaultPlanSelectForm} from "../defaults/palnSelectForm";
import {/*checkSearchResult,*/ getPlanSelectInfoData, initSearchParams} from "../utils/search";
// import freeSpace from "./FreeSpace";
import FreeSpaceSection from "./FreeSpace";
import LoadingOverlay from "react-loading-overlay-ts";
import {getPlansTotalPrice, hasActivityStock} from "../utils/plan";
import {defaultBasicTotalPrice, /*getBasicTotalPrice*/} from "../utils/price";
// import { airCode, anaHasNoFlightAirCode, jalHasNoFlightAirCode } from "../config/Airport";
import Error500 from "./error/Error500";
import ServiceStop from "./ServiceStop";
import _ from "lodash";

import {getSearchURL} from "../utils/search";
import {validateFlightStartDate} from "../utils/validate";
// import {devLog} from "../utils/errors";

import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import {sellerProductsType} from "../types/sellerProducts";
import {FormSessionContext, FormSessionType} from "../hooks/FormSession";
import dayjs from "dayjs";
import {getHotelSort} from "../utils/hotel/getHotelSort";


const PlanSelectPage: React.FC = () => {

  // use navigate
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  //session check
  const {checkSession} = useContext(FormSessionContext);

  // 検索ボックスのパラメータ
  const search = useLocation().search.slice(1);
  const [searchParams, setSearchParams] = useState<PlanSearch>(initSearchParams(search));
  const [searchLoading, setSearchLoading] = useState(true);
  const [planType, setPlanType] = useState("");
  let notFound = false;
  if (!validateFlightStartDate(searchParams.start)) {
    notFound = true;
  }
  
  // is loading for any sections, you can add new loading value for section
  const [isLoadingHotel, setLoadingHotel] = useState(true);
  const [planSelectFormData, setPlanSelectFormData] = useState<PlanSelectFormData>(defaultPlanSelectForm);
  const [is500Error, setIs500Error] = useState(false);
  const [isNotFound, setIsNotFound] = useState(notFound);
  const [isPlan, setIsPlan] = useState(true);

  const [logo, setLogo] = useState<string|undefined>(undefined);

  // get url params
  const urlParams = useParams<{ lang: string; id: string; }>();

  // 包括オプション
  const [activities, setActivities] = useState<IActivity[]>([]);

  // フライトと宿泊の表示非表示（選択できるできないのhandle）
  const [canSelect, setCanSelect] = useState(false);
  const [sellerId, setSellerId] = useState<string|undefined>(undefined);
  const [sellerInfo, setSellerInfo] = useState<sellerInfo|undefined>(undefined);

  // // entities
  // const [currentFlightEntities /*, setFlightEntities*/] = useState<FlightEntity[]>([])

  const [actionHotel, setActionHotel] = useState(false);
  const [hotelAllData, setHotelAllData] = useState<HotelAllSearchResponse>({
    total: 0,
    currentPage: 0,
    totalPage: 0,
    search_sort: 0,
    status: 0,
    success: true,
    error: undefined,
    hotelList: [],
    subAreaCodes: "",
    hotelTypeCodes: "",
  }); // ホテル一覧で取得したデータ
  const [actionInitHotel, setActionInitHotel] = useState(false);
  const [updateHotelSearchParams, setHotelSearchParams] = useState<hotelSearchQuery>({
    area_code: '',
    target: '',
    code: '',
    search_sort: 0,
    query: searchParams,
    language_code: i18n.language,
  })
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [flightTotal, setFlightTotal] = useState(0);


  // 検索
  const handleSearch = (q: PlanSearch) => {
    setSearchParams({...q})
    window.location.href = getSearchURL(q);
  }

  /**
   * 初回実行処理
   */
  useEffect(() => {
    setPlanSelectFormData((prevState) => (
      {...prevState,
        id : urlParams.id?? "",
        info: getPlanSelectInfoData(searchParams, t),
        searchParams: searchParams,
        timestamp: dayjs().format(),
        lang: i18n.language
      }
    ));

    (async () => {
      if(activities.length <= 0) {
        // setLoadingComprehenshive(true);

        try {
          const response = await apiClient.post("/plan", JSON.stringify({
            "plan" : urlParams.id,
            "use_date" : searchParams?.start,
            "language_code" : i18n.language,
          }));

          if (response.data.status === 500) {
            throw Error("Server Error");
          }

          // setLoadingComprehenshive(false);
          const results = response.data['plans'];
          const resultsOptions = response.data['options'];
          const data = results as IActivity[];

          if (response.data.info.status === 200) {
            const logoImage = response.data.sellerIndo.companyInfo.logo;
            const type = response.data.info.body.type?? "option";
            const arrival = response.data.sellerIndo.useAirport.arrival;
            setLogo(logoImage);
            searchParams.to = arrival;
            searchParams.c_airport_from = arrival;
            
            
            setPlanSelectFormData((prevState) => (
              {
                ...prevState,
                activity: data,
                planData: response.data,
                options: resultsOptions,
                logo: logoImage?? "",
                planType: type,
                searchParams:searchParams,
                sellerProduct: response.data.product,
              }))
            setActivities(data);
            setSellerId(response.data.info.body.userId)
            setPlanType(type);
            setSellerInfo(response.data.sellerIndo);
            setSearchParams({...searchParams});
            if (type === "option") {
              setCanSelect(true);
            }
            
            // NOTE: APIから返ってくる(=Firestoreに保存されている)のが日本語なのでそのままでOK
            if (response.data.sellerIndo.active === '停止中') {
              setIsPlan(false);
            }
          } else {
            setIsPlan(false);
          }

        } catch (error) {
          console.error(error);
          setIs500Error(true);
          // setLoadingComprehenshive(false);
        }
          
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [freeSpaceContent, setFreeSpaceContent] = React.useState<FreeSpace>();
  useEffect(() => {
    // フリースペース取得（ローディング無し）
    if(sellerId) {
      (async () => {
        if (!planSelectFormData.freeSpace) {

          try {
            const response = await apiClient.post("/freeSpace", JSON.stringify({
              seller: sellerId,
              languageCode: i18n.language,
            }));

            const data = response.data.freeSpace;
            const adminData = response.data.adminFreeSpace;
            const updatePlanSelectFormData = planSelectFormData
            if (data || adminData) {
              updatePlanSelectFormData.freeSpace = {
                freeSpace: data.body,
                adminFreeSpace: adminData.body
              }
              setFreeSpaceContent({...updatePlanSelectFormData.freeSpace})
              setPlanSelectFormData({...updatePlanSelectFormData})
            }

          } catch (error) {
            setIs500Error(true);
            console.error(error);
          }
          
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sellerId])

  useEffect(() => {

    // 基本プランの日程選択
    if (searchParams !== undefined && planSelectFormData.planData !== undefined) {
      setActionHotel(true);
      setSearchLoading(false);

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);


  const [isListLoading, setIsListLoading] = useState(false);
  const [hotelController, setHotelController] = useState(new AbortController())

  /**
   * 並び順を指定してホテル検索
   * @param type
   * @param val
   * @param areaCode
   * @param hotelType
   */
  const hotelSort = (type: string, val: number) => {
    if (isLoadingHotel) {
      return;
    }
    if (sellerInfo !== undefined && searchParams !== undefined) {
      const hotelAreaCode = getHotelAreaCode(planSelectFormData);
      const hotelSearchParams = {
        sort: 1,
        target: sellerInfo.hotelSettings.target,
        code: sellerInfo.hotelSettings.hotelCode,
        searchKeyWordType: '',
        searchKeyWordMode: 1,
        searchKeyWordCode: '',
        searchHotelType: '',
      }
      switch (type) {
        case "max_price":
          hotelSearchParams.sort = 2;
          break;
        case "min_price":
          hotelSearchParams.sort = 1;
          break;
        case "recommend":
          hotelSearchParams.sort = 0;
          break;
        default:
          break;
      }

      // 基本プランに商品が設定されている場合、商品のデータを使用する
      if (planSelectFormData.sellerProduct) {
        hotelSearchParams.target = planSelectFormData.sellerProduct.hotelSettings.target;
        hotelSearchParams.code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
      }

      setHotelSearchParams((prevState) => ({
        ...prevState,
        area_code: hotelAreaCode,
        target: hotelSearchParams.target,
        code: hotelSearchParams.code,
        search_sort: hotelSearchParams.sort,
        search_keyword_type: hotelSearchParams.searchKeyWordType,
        search_keyword_mode: hotelSearchParams.searchKeyWordMode,
        search_keyword_code: hotelSearchParams.searchKeyWordCode,
        search_hotel_type_code: hotelSearchParams.searchHotelType,
        paged: 1,
        language_code: i18n.language
      }))
      setLoadingHotel(true);
      setActionInitHotel(true);
      setHotelAllData((prevState) => ({
        ...prevState,
        hotelList: []
      }))
      hotelController.abort();

    }
  };
  
  /**
   * ホテル一覧検索
   */
  const searchHotelList = async (
    searchQuery: hotelSearchQuery,
  ) => {
    setLoadingHotel(true);
    const abortController = new AbortController()
    const hotelResult = await getHotel(abortController, searchQuery);

    const updatePlanSelectFormData = planSelectFormData;
    if (hotelResult && hotelResult?.hotelList.length > 0) {
      updatePlanSelectFormData.isHotels = true;
      const firstHotelIndex = hotelResult.hotelList.findIndex((hotelList) => {
        return hotelList.sold_out_flg !== 1;
      })
      updatePlanSelectFormData.hotel = [
        {
          useDate: searchParams.checkin,
          detail: hotelResult.hotelList[firstHotelIndex],
          checkIn: searchParams.checkin,
          checkOut: searchParams.checkout,
          area: hotelResult.hotelList[firstHotelIndex]?.sub_area_code ?? "",
          total: hotelResult.hotelList[firstHotelIndex]?.min_price ?? 0,
        },
      ];
      updatePlanSelectFormData.hotelData = hotelResult.hotelList;
      setPlanSelectFormData((prevState) => ({
        ...prevState,
        isHotels: updatePlanSelectFormData.isHotels,
        hotelData: updatePlanSelectFormData.hotelData,
        hotel: updatePlanSelectFormData.hotel,
        hotelTotal: updatePlanSelectFormData.hotel[0]?.detail?.min_price ?? 0,
      }));

      setHotelAllData({...hotelResult});
      setIsNotFound(false);
    } else {
      setIsNotFound(true);
      return;
    }
  };
  
  /**
   * ホテル検索（初回）
   */
  useEffect(() => {
    /**
     * ホテル情報取得
     */
    if (sellerInfo !== undefined && searchParams !== undefined && actionHotel) {
      let target = sellerInfo.hotelSettings.target;
      let code = sellerInfo.hotelSettings.hotelCode;
      const hotelAreaCode = getHotelAreaCode(planSelectFormData);

      // 基本プランに商品が設定されている場合、商品のデータを使用する
      if (planSelectFormData.sellerProduct) {
        target = planSelectFormData.sellerProduct.hotelSettings.target;
        code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
      }

      // ホテル（宿泊施設）
      if (hotelAllData.currentPage === 0) {
        let searchSort = 1;
        if (target === 'ホテル指定') {
          searchSort = getHotelSort(planSelectFormData?.sellerProduct?.hotelSettings.sort ?? '');
        }

        setHotelSearchParams((prevState) => ({
          ...prevState,
          search_sort: searchSort
        }))
        searchHotelList({
          area_code: hotelAreaCode,
          target: target,
          code: code,
          query: searchParams,
          paged: 1,
          search_sort: searchSort,
          language_code: i18n.language,
        });
      }
    }
  }, [actionHotel]);

  useEffect(() => {
    if (hotelAllData && canSelect) {
      updateBasicTotalPrice();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hotelAllData, canSelect, flightTotal, planSelectFormData.hotel])


  const updateSelectedHotel = (selectedHotel: selectHotelDetail) => {
    const updateHotel: PlanSelectFromHotelType[] = [
      {
        useDate: searchParams.checkin,
        detail: selectedHotel,
        checkIn: searchParams.checkin,
        checkOut: searchParams.checkout,
        area: selectedHotel?.sub_area_code ?? "",
        total: selectedHotel?.min_price ?? 0,
      },
    ];
    setPlanSelectFormData((prevState) => ({
      ...prevState,
      hotel: updateHotel,
      hotelTotal: updateHotel[0]?.detail?.min_price ?? 0,
    }));
  }

  useEffect(() => {
    if (actionInitHotel) {
      if (hotelAllData.hotelList.length > 0) {
        setHotelAllData((prevState) => ({
          ...prevState,
          hotelList: [],
        }));
      } else {
        setActionInitHotel(false);
        // ホテル一覧検索
        searchHotelList(updateHotelSearchParams);
      }
    } else if (hotelAllData.hotelList.length > 0 && hotelAllData.currentPage !== hotelAllData.totalPage) {
      addHotelList();
    } else {
      setIsListLoading(false);
    }
  }, [hotelAllData])

  const abortController = new AbortController()
  const addHotelList = () => {
    (async () => {
      if ( hotelAllData.totalPage > 1) {
        setIsListLoading(true)
        if (sellerInfo !== undefined && searchParams !== undefined) {
          const paged = hotelAllData.currentPage + 1;
          const hotelAreaCode = getHotelAreaCode(planSelectFormData);
          let target = sellerInfo.hotelSettings.target;
          let code = sellerInfo.hotelSettings.hotelCode;

          // 基本プランに商品が設定されている場合、商品のデータを使用する
          if (planSelectFormData.sellerProduct) {
            target = planSelectFormData.sellerProduct.hotelSettings.target;
            code = planSelectFormData.sellerProduct.hotelSettings.hotelCode;
          }

          setHotelController(abortController);
          updateHotelSearchParams.area_code = hotelAreaCode;
          updateHotelSearchParams.target = target;
          updateHotelSearchParams.code = code;
          updateHotelSearchParams.query = searchParams;
          updateHotelSearchParams.paged = paged;
          updateHotelSearchParams.language_code = i18n.language;
          // ホテル一覧検索
          const hotelResult = await getHotel(
            abortController,
            updateHotelSearchParams);
          if (hotelResult && hotelResult?.hotelList.length > 0 && hotelAllData?.hotelList){
            const updateHotelList = [...hotelAllData?.hotelList].concat(hotelResult?.hotelList);
            setHotelAllData((prevState) => ({
              ...prevState,
              hotelList: updateHotelList,
              currentPage: hotelResult.currentPage
            }))
          }
          // scrollToHotelSection(); // `宿泊の選択`の場所にスクロールする
        }

        setIsListLoading(false);
      }
    })();
  }
  
  
  const updateBasicTotalPrice = () => {
    /**
     * 基本料金設定（データが変更されるたびに再計算）
     */
    if (planSelectFormData.hotelTotal > 0) {
      const total = defaultBasicTotalPrice(planSelectFormData);
      if (total > 0) {
        setPlanSelectFormData((prevState) => ({...prevState, basicPriceTotal:total}))
        setLoadingHotel(false);
      }
    }
  }

  /**
   * 基本プラン(アクティビティ)設定更新
   * @param acts
   */
  const setActivityCallBack = (acts: IActivity[]) => {
    setActivities(acts)
    setPlanSelectFormData((prevState) => ({...prevState, activity:acts}))
    // const active = acts.filter((item) => item.active === true);
    const planTotal = getPlansTotalPrice(acts, planSelectFormData);
    setPlanSelectFormData((prevState) => ({...prevState, planTotal:planTotal}))
    // フライト非表示解除チェック
    let activeSelectCheck = false
    const noSelectActivity = acts.find((activity) => {
      return !activity.active && hasActivityStock(activity)
    })
    if (!noSelectActivity) {
      activeSelectCheck = true;
    }
    setCanSelect(activeSelectCheck)
  }

  const [activityStockExist, setActivityStockExist] = useState(true);
  const sessionContext: FormSessionType = useContext(FormSessionContext);

  /**
   * 予約 確認・変更画面に遷移
   * @param planSelectFormData
   */
  const handleMove = (planSelectFormData:PlanSelectFormData) => {
    /**
     * 基本料金設定（データが変更されるたびに再計算）
     */
    updateBasicTotalPrice();

    if(planSelectFormData.hotel.length > 0 && planSelectFormData.hotel[0].plan !== undefined) {
      sessionContext.checkSession(planSelectFormData);
      navigate(ROUTES.PlanSelectConfirm.replace(":lang", i18n.language), { state: {
          data: planSelectFormData
        } })
    }
  }

  return (
    <MainTemplate title={t("Common.デジタルDMOプラットフォーム")} fixedBottom={true} logo={logo}  sellerId={planSelectFormData?.planData?.sellerIndo.sellerId} supportDial={planSelectFormData?.planData?.sellerIndo.supportDial} planSelectFormData={planSelectFormData}>
      <div className="bgc-white border-bottom-gray pb-16 pb-24-md">
        <div className="wrap-content-900-no_padding">
          <PartsStep activeStep={1}></PartsStep>
        </div>

        <FreeSpaceSection freeSpaceContent={freeSpaceContent}></FreeSpaceSection>

        <div className="wrap-content-900">
          {/* <!--アラート--> */}
          <div className="box-alert-red-small icon-alert d-n">
            {/* <p className="fz-16">選択した内容、旅行代金をご確認ください</p> */}
            {/* <p className="fz-16">確認您的旅行商品的內容及費用</p> */}
            <p className="fz-16">{t("PlanSelectTop.選択した内容、旅行代金をご確認ください")}</p>
          </div>

          {/* 選択中の検索条件 */}
          {/* <p className="fz-12 fz-13-md fw-b mt-0 mt-20-md mb-4">您所選擇中的搜尋條件</p> */}
          <p className="fz-12 fz-13-md fw-b mt-0 mt-20-md mb-4">{t("PlanSelectTop.選択中の検索条件")}</p>
          <div className="box-light-gray xsmall">
            <div className="search-result">
              {/* <dl className="search-result-item">
                <dt className="search-result-item-dt">利用空港</dt>
                <dd className="search-result-item-dd">
                  <ul className="search-result-item-dd-flight">
                    <li key={"search_from"} className="search-result-item-dd-flight-item">
                      {getFlightCodeLabel(searchParams?.from)}
                    </li>
                    <li key={"search_to"} className="search-result-item-dd-flight-item">
                      {getFlightCodeLabel(searchParams?.to)}
                    </li>
                  </ul>
                </dd>
              </dl> */}

              <dl className="search-result-item">
                {/* <dt className="search-result-item-dt">旅遊日期</dt> */}
                <dt className="search-result-item-dt">{t("PlanSelectTop.旅行期間")}</dt>
                <dd className="search-result-item-dd">
                  <ul className="search-result-item-dd-date">
                    <li key={"search_start"} className="search-result-item-dd-date-item">
                      {dateFormat(searchParams?.start, "YYYY/MM/DD")}
                    </li>
                    <li key={"search_end"} className="search-result-item-dd-date-item">
                      {dateFormat(searchParams?.end, "YYYY/MM/DD")}
                    </li>
                  </ul>
                </dd>
              </dl>

              <dl className="search-result-item">
                {/* <dt className="search-result-item-dt">房間數・人數</dt> */}
                <dt className="search-result-item-dt">{t("PlanSelectTop.部屋数・人数")}</dt>
                <dd className="search-result-item-dd">
                  {getHotelRoomPeopleLabel(searchParams?.roomDetail, searchParams?.rooms, t)}
                  {/* 1室・4名（大人1/子供3） */}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* 検索ボックス */}
      <TopSearchbox query={searchParams} callbackSearch={handleSearch}></TopSearchbox>

      {is500Error && (
        <Error500 />
      )}

      {isNotFound && (
        <SearchNotFound />
      )}

      {!is500Error && !isNotFound && (
        isPlan ? ( // プランあり
          <>
            {searchLoading ? ( // ローディング中の表示
              <div className="mt-32 mt-40-md">
                <div className="wrap-content-900">
                  <LoadingOverlay active={searchLoading}>
                    <div className="pb-100 pt-80"></div>
                  </LoadingOverlay>
                </div>
              </div>
            )
            : activityStockExist ? ( // 選択可能なプランあり
              <>
                {planType !== "option" && (
                  <>
                    {/* 基本プラン */}
                    <SectionPlan
                      activityStockExist={activityStockExist}
                      setActivityStockExist={setActivityStockExist}
                      planSelectFormData={planSelectFormData}
                      activities={activities}
                      searchParams={searchParams}
                      // isLoading={isLoadingComprehenshive}
                      setActivities={setActivityCallBack}
                    />
                  </>
                )}

                {/* ホテル選択 */}
                <SectionStay
                  planSelectFormData={planSelectFormData}
                  selectPlan={handleMove}
                  isLoading={isLoadingHotel}
                  isListLoading={isListLoading}
                  canSelect={canSelect}
                  callback={()=> {}}
                  sort={hotelSort}
                  hotelAllData={hotelAllData}
                  hotelController={hotelController}
                  handleHotelSelected={updateSelectedHotel}
                />
              </>
            )
            : (<SearchNotFound />) // 選択可能なプランなし
            }
            {/* <div className="parts-bottom-fixed">
              <div className="wrap-content-900-no_padding ">
                <div className="box-white fixed-padding">
                  <div className="d-f ai-c jc-sb jc-fe-md">
                    <p className="fw-b pr-24">現在選擇中的合計費用</p>
                    <p className="fz-24 fz-28-md c-red fw-b">
                      {planSelectFormData.basicPriceTotal.toLocaleString()}
                      <span className="fz-14 fz-16-md">日圓(税込)</span>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <PartsTotalPrice totalPrice={planSelectFormData.basicPriceTotal} planSelectFormData={planSelectFormData} />
          </>
        )
        : (<ServiceStop></ServiceStop>) // プランなし
      )}
    </MainTemplate>
  );
};

export default PlanSelectPage;
